import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'reactn';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { ImagePreview } from 'common/components/ImagePreview';
import { ColorType } from 'types';
import { ObjectFitProperty } from 'csstype';
import { S } from './field-image-upload.styles';
import { FieldImageUploadArea } from './field-image-upload-area.component';
import { FieldFileUploadIcon } from '../field-file-upload/field-file-upload-icon.component';
import { AcceptableImageSize } from '../AdvanceForm/AdvanceForm.interface';

interface Props {
  onUpload: (base64: any, filename: string) => void;
  type: string;
  label?: string;
  defaultText: string;
  removeCallback?: () => void;
  onRemoveImage?: () => void;
  onDropFailCallback?: (filenames: string[]) => void;
  value?: string;
  config?: {
    ratio: string;
    px?: string;
  };
  dropzoneOptions?: DropzoneOptions;
  uploadIcon?: JSX.Element;
  style?: React.CSSProperties;
  imageHeight?: string | number;
  imageWidth?: string | number;
  objectFitMode?: ObjectFitProperty;
  defaultImage?: string;
  acceptText?: string;
  acceptableImageSize?: AcceptableImageSize;
}

export const FieldImageUpload: React.FC<Props> = ({
  onUpload,
  type,
  defaultText,
  removeCallback,
  onRemoveImage,
  onDropFailCallback,
  value,
  config,
  dropzoneOptions,
  uploadIcon,
  style,
  imageHeight,
  imageWidth,
  objectFitMode,
  defaultImage,
  acceptText,
  acceptableImageSize,
}) => {
  const [fileName, setFileName] = useState(defaultText);
  const [base64Value, setBase64Value] = useState<string | undefined>(value);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const setSnackbar = useDispatch('setUtilsSnackbar');
  const closeSnackbar = useDispatch('closeSnackbar');

  const hasUploadedFile = !!base64Value && base64Value !== '';
  const acceptConfig = (dropzoneOptions?.accept as string) || 'image/*';
  const acceptSettings = acceptConfig.split('/');
  const acceptType = acceptSettings.length > 0 ? acceptSettings[1] : acceptConfig;
  const acceptTypes = acceptConfig.replace(/image\//g, ' ');

  useEffect(() => {
    if (!base64Value && defaultImage) {
      setBase64Value(defaultImage);
    }
  }, [defaultImage, base64Value]);

  useEffect(() => {
    if (errorMessage) {
      setSnackbar({
        variant: ColorType.DANGER,
        open: !!errorMessage,
        message: errorMessage,
        onCloseSnackbar: () => {
          closeSnackbar({});
          setErrorMessage('');
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  const setImageToUpload = (imgBase64: string | ArrayBuffer, file: { name: string }): void => {
    onUpload(imgBase64, file.name);
    setBase64Value(imgBase64?.toString());
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length <= 0) setErrorMessage(`Acceptable image file extension are ${acceptTypes} only`);

      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const imgBase64 = event.target.result;
            if (imgBase64) {
              if (acceptableImageSize) {
                const image = new Image();
                image.src = imgBase64.toString();
                image.onload = () => {
                  if (
                    image.width <= acceptableImageSize.maxWidth &&
                    image.height <= acceptableImageSize.maxHeight &&
                    image.width >= acceptableImageSize.minWidth &&
                    image.height >= acceptableImageSize.minHeight
                  ) {
                    setImageToUpload(imgBase64, file);
                  } else {
                    setErrorMessage(
                      `Acceptable image pixels around min ${acceptableImageSize.minWidth}x${acceptableImageSize.minHeight}px and max ${acceptableImageSize.maxWidth}x${acceptableImageSize.maxHeight}px`,
                    );
                  }
                };
              } else {
                setImageToUpload(imgBase64, file);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        setFileName(file.name);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUpload],
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      if (!onDropFailCallback) return;
      const fileNames = rejectedFiles.map((r: any) => r.name);
      onDropFailCallback(fileNames);
    },
    [onDropFailCallback],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    ...dropzoneOptions,
  });

  const handleRemove = (): void => {
    if (onRemoveImage) {
      onRemoveImage();
      return;
    }

    onUpload('', '');
    setFileName(defaultText);
    setBase64Value(undefined);
    if (removeCallback) {
      removeCallback();
    }
  };

  const enableRemove = !(dropzoneOptions?.disabled || false);
  const uploadArea = base64Value ? (
    <ImagePreview
      getRootProps={getRootProps}
      imageSource={base64Value}
      isActive={hasUploadedFile}
      handleRemove={enableRemove ? handleRemove : undefined}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
      objectFitMode={objectFitMode}
    />
  ) : (
    <FieldImageUploadArea getRootProps={getRootProps} style={style}>
      {uploadIcon || <FieldFileUploadIcon active={hasUploadedFile} />}
      <span>{fileName}</span>
      <span>
        {acceptText ?? acceptType.toUpperCase()}
        {config ? `, ratio ${config.ratio} ${config.px ? `${config.px} px` : ''}` : ''}
      </span>
    </FieldImageUploadArea>
  );

  return (
    <>
      <S.DropzoneContainer active={hasUploadedFile} style={style}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <FieldImageUploadArea getRootProps={getRootProps} style={style}>
            <p>{`Drop the ${type} file here ...`}</p>
          </FieldImageUploadArea>
        ) : (
          uploadArea
        )}
      </S.DropzoneContainer>
    </>
  );
};
