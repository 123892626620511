import React from 'react';
import { 
  Checkbox, 
  FormGroup, 
  FormControlLabel 
} from '@material-ui/core';
import { useStyles } from './field-multi-checkboxes.styles';

interface CheckboxItem {
  value: string;
  label: string;
}

interface FieldMultiCheckboxProps {
  name?: string;
  items?: CheckboxItem[];
  value?: string[] | null;
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  defaultValue?: CheckboxItem[];
  isClearable?: boolean;
}

export const FieldMultiCheckbox: React.FC<FieldMultiCheckboxProps> = ({
  items = [],
  value = [],
  onChange,
  disabled,
  onFocus,
  onBlur,
  defaultValue = [],
  isClearable = false
}) => {
  const classes = useStyles();

  const selectedValues: string[] = value && value.length > 0 
  ? value 
  : defaultValue.map(item => item.value);

  const handleChange = (itemValue: string): void => {
    const currentValues = new Set(selectedValues);
    
    if (currentValues.has(itemValue)) {
      currentValues.delete(itemValue);
      
      if (currentValues.size === 0 && !isClearable) {
        return;
      }
    } else {
      currentValues.add(itemValue);
    }

    const newValue = Array.from(currentValues);
    
    if (onChange) {
      onChange(newValue);
    }
  };

  const checkboxItems = items.length > 0 ? items : defaultValue;

  return (
    <FormGroup className={classes.formGroup}>
      {checkboxItems.map((item) => (
        <FormControlLabel
          key={item.value}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={selectedValues.includes(item.value)}
              onChange={() => handleChange(item.value)}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          }
          label={item.label}
        />
      ))}
    </FormGroup>
  );
};