import React from 'react';
import Select, { components } from 'react-select';
import AsyncSelect, { Async } from 'react-select/async';

import SearchIcon from '@material-ui/icons/Search';
import { primaryStyle } from './async-auto-complete.styles';
import { AsyncAutoCompleteProps, FetchWithoutCallback } from './async-auto-complete.interface';

const SearchIconComponent = (props: any): any => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  );
};

interface Props extends AsyncAutoCompleteProps {
  selectRef?: React.RefObject<Async<HTMLInputElement | any>>;
  value?: any;
  datasource?: any;
  inputValue?: string;
  isClearable?: boolean;
  defaultValue?: any;
  isDisabled?: boolean;
}

export const AsyncAutoComplete: React.FC<Props> = ({
  height,
  onBlur,
  placeholder,
  fetchData,
  handleChange,
  styles,
  autoFocus,
  selectRef,
  value,
  datasource,
  inputValue,
  isClearable,
  defaultValue,
  isDisabled,
}) =>
  datasource ? (
    <Select
      options={datasource}
      value={value}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      styles={styles || primaryStyle}
      cacheOptions
      maxMenuHeight={height || 300}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={(e: any) => {
        handleChange(e);
      }}
      onInputChange={(input) => {
        const call = fetchData as FetchWithoutCallback;
        call(input);
      }}
      components={{ DropdownIndicator: SearchIconComponent }}
      isDisabled={isDisabled}
    />
  ) : (
    <AsyncSelect
      autoFocus={autoFocus}
      styles={styles || primaryStyle}
      cacheOptions
      maxMenuHeight={height || 300}
      loadOptions={fetchData}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={(e: any) => {
        handleChange(e);
      }}
      ref={selectRef}
      inputValue={inputValue}
      isClearable={isClearable}
      components={{ DropdownIndicator: SearchIconComponent }}
      isDisabled={isDisabled}
    />
  );
