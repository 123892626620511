/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React from 'reactn';
import { FormikHandlers } from 'formik';
import { Typography } from '@material-ui/core';
import { ShowIf } from 'common/components/ShowIf';

import { ColorType } from 'types';
import { FieldInput, FieldSwitch, FieldMultiInput, FieldMultiSelect, Button } from 'common/components';
import { FieldDateInput, FieldImageUpload, FieldMultiCheckbox } from '../..';

import { ColumnType } from '../AdvanceForm.types';
import { MaskedInput } from '../../field-mask';
import { FieldRichText } from '../../field-rich-text/field-rich-text.component';
import styles from '../AdvanceForm.module.css';
import moment from 'moment';
import { DateFormats } from 'common/constants';

interface Props {
  type: string;
  name: string;
  values: string | boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  config?: any;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
  setFocusedField?: (name: string) => void;
  initialValue?: string;
  hasError?: boolean;
  buttonColor?: ColorType;
  buttonOnClick?: () => Promise<void>;
  buttonDisabled?: boolean;
}

export const AdvanceFormField: React.FC<Props> = ({
  type,
  name,
  placeholder,
  values,
  onChange,
  onBlur,
  config,
  isRequired,
  setFieldValue,
  disabled,
  setFocusedField,
  helperText,
  initialValue,
  hasError,
  buttonColor,
  buttonOnClick,
  buttonDisabled,
}): JSX.Element => {
  const validation =
    values === '' ? { error: hasError || isRequired, id: 'outlined-error-helper-text' } : { error: hasError };
  const multiline = config?.isMultiline ? { multiline: true, rows: config.rows || 4 } : {};
  const inputProp = config?.inputProps ? { inputProps: config.inputProps } : {};

  if (config?.fieldMultiSelectProps) {
    config.fieldMultiSelectProps.defaultValue = Array.isArray(values) ? values : [{ label: values, value: values }];
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e);
  
    if (config?.linkedFields?.length) {
      config.linkedFields.forEach((linkedField: { fieldName: string; value: (arg0: string) => any; }) => {
        if (linkedField.fieldName) {
          const valueToSet = linkedField.value ? linkedField.value(e.target.value) : e.target.value;
          setFieldValue(linkedField.fieldName, valueToSet);
        }
      });
    }
  };

  const onFocus = (): void => {
    if (setFocusedField) {
      setFocusedField(name);
    }
  };

  const textFieldAttribute = {
    name,
    placeholder,
    value: values,
    onChange,
    minWidth: 100,
    disabled,
    onFocus,
    onBlur,
    className: disabled ? styles.disabled : '',
  };

  switch (type) {
    case ColumnType.LOCALE_DATE:
      return (
        <>
          <FieldDateInput
            data-testid={`input-${name}`}
            {...validation}
            name={name}
            value={typeof values === 'string' && moment(values).isValid() ? moment(values).toDate() : null} 
            onChange={(date) => setFieldValue(name, date ? moment(date).format(config?.dateFormat || DateFormats.YYYY_MM_DD) : '')}
            format={config?.dateFormat || DateFormats.YYYY_MM_DD}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{ ...inputProp, className: disabled ? styles.disabled : '' }}
          />
          {helperText && <Typography variant="body2">{helperText}</Typography>}
        </>
      );
    case ColumnType.LINK:
      case ColumnType.TEXT:
        return (
          <>
            <FieldInput
              data-testid={`input-${name}`}
              {...validation}
              {...multiline}
              {...inputProp}
              {...textFieldAttribute}
              type={type}
              onChange={handleTextFieldChange}
            />
      
            <ShowIf condition={!!helperText}>
              <Typography variant="body2">{helperText}</Typography>
            </ShowIf>
          </>
        );
    case ColumnType.NUMBER:
      return (
        <>
          <FieldInput
            data-testid={`input-${name}`}
            {...validation}
            {...multiline}
            {...inputProp}
            {...textFieldAttribute}
            type={type}
          />

          <ShowIf condition={!!helperText}>
            <Typography variant="body2">{helperText}</Typography>
          </ShowIf>
        </>
      );
    case ColumnType.MASK:
      return (
        <>
          <MaskedInput
            data-testid={`input-${name}`}
            {...validation}
            {...multiline}
            {...inputProp}
            {...textFieldAttribute}
            type={type}
            mask={config?.mask}
            initialValue={initialValue}
          />

          <ShowIf condition={!!helperText}>
            <Typography variant="body2">{helperText}</Typography>
          </ShowIf>
        </>
      );
    case ColumnType.ARRAY:
      return (
        <FieldMultiInput
          {...validation}
          name={name}
          values={values as string}
          setFieldValue={setFieldValue}
          variant="outlined"
          placeholder={placeholder}
          style={{ width: '100%' }}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
    case ColumnType.SWITCH:
      return (
        <FieldSwitch
          name={name}
          values={values as boolean}
          onChange={onChange}
          label={values ? config?.selectValue?.yes || 'on' : config?.selectValue?.no || 'off'}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
    case ColumnType.FILEUPLOAD:
      return (
        <FieldImageUpload
          onUpload={(base64: string) => {
            setFieldValue(name, base64);
          }}
          type="file"
          defaultText="Drop your image here"
          removeCallback={config.removeCallback}
          value={values as string}
          config={{
            ratio: '1:1',
            px: '600x600',
          }}
          dropzoneOptions={{
            ...config.dropzoneOptions,
            disabled,
          }}
          acceptableImageSize={config?.acceptableImageSize}
        />
      );
    case ColumnType.SELECT:
      return (
        <FieldMultiSelect
          {...validation}
          data-testid={`multiSelect-${name}`}
          key={values.toString()}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          handleChange={config.handleChange}
          setFieldValue={setFieldValue}
          {...config.fieldMultiSelectProps}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
      case ColumnType.CHECKBOX:
        return (
          <FieldMultiCheckbox
            name={name}
            value={values as string}
            items={config?.checkboxItems || []} 
            onChange={(newValue) => {
              if (setFieldValue) {
                setFieldValue(name, newValue);
              }
            }}
            {...config.fieldMultiCheckboxProps}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        );
    case ColumnType.BUTTON:
      return (
        <>
          <Button
            color={buttonColor || ColorType.PRIMARY}
            onClick={buttonOnClick}
            style={{
              padding: '10px 14px',
              marginTop: '4%',
              ...config?.buttonStyle,
            }}
            disabled={buttonDisabled}
          >
            {name}
          </Button>
          <ShowIf condition={!!values}>
            <Typography variant="body2" style={{ position: 'initial', marginTop: 8 }}>
              {values || ''}
              <ShowIf condition={!!helperText}>
                <Typography variant="subtitle2">{helperText || ''}</Typography>
              </ShowIf>
            </Typography>
          </ShowIf>
        </>
      );
    case ColumnType.MARKDOWN:
      return (
        <>
          <FieldRichText
            data-testid={`input-${name}`}
            name={name}
            value={values as string}
            setFieldValue={setFieldValue}
            variant="outlined"
            placeholder={placeholder}
            style={{ width: '100%', ...config?.columnStyle }}
            disabled={disabled}
            onFocus={onFocus}
            type={type}
          />
          <ShowIf condition={!!helperText}>
            <Typography variant="body2">{helperText}</Typography>
          </ShowIf>
        </>
      );
    default:
      return <></>;
  }
};
