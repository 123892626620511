/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import React, { useState, useEffect } from 'reactn';
import { useFormik } from 'formik';
import { ColorType } from 'types';
import { Schema, object } from 'yup';

import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { Grid, Typography, Divider } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button, ConditionalRender } from 'common/components';
import { COLORS } from 'common/constants';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AdvanceFormColumn } from './components';
import { AdvanceFormProps, LayoutColumn, LayoutRow, FormikAccumulator, GridXs } from './AdvanceForm.interface';
import { ColumnType, FormStateType } from './AdvanceForm.types';

import { S } from './AdvanceForm.styles';
import styles from './AdvanceForm.module.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const AdvanceForm: React.FC<AdvanceFormProps> = ({
  currentUserRole,
  layout,
  initialFormState,
  blacklist,
  options = {},
  onSubmit,
  onCancel,
  onChangeFormState,
  onChangeValues,
  acceptableImageSize,
  title,
  isButtonUpperRightSide,
  innerRef,
  onSubmitStart,
  onSubmitEnd,
}): JSX.Element => {
  const { hideEdit, hideHeader, cancelCaption, saveCaption, submitCaption } = options;
  const classes = useStyles();
  const [formState, setFormState] = useState(initialFormState);
  const [activeField, setActiveField] = useState('');

  const [debugLogs, setDebugLogs] = useState<{ [k: string]: any }>({});

  const setFocusedField = (name: string): void => {
    setActiveField(name);
  };

  useEffect(() => {
    if (onChangeFormState) {
      onChangeFormState(formState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const { search } = useLocation();

  const queryParams = qs.parse(search);
  const debug = queryParams.debug === 'enabled';

  const requiredColumns: string[] = [];
  const validationSchema: {
    [any: string]: Schema<any>;
  } = {};

  const getColumnFieldValueMapping = (acc: FormikAccumulator, column: LayoutColumn): FormikAccumulator => {
    if (column.type !== ColumnType.ROWS) {
      acc[column.name] = column.value as string | boolean;
      if (column.validation && !column.isNotVisibleInEdit) {
        validationSchema[column.name] = column.validation;
      }
      if (((column?.validation) as unknown as any)?._exclusive?.required) {
        requiredColumns.push(column.name);
      }      
      return acc;
    }
    const columnValue = (column.value as unknown) as LayoutColumn[];
    const subFields = columnValue.reduce(getColumnFieldValueMapping, {});
    return {
      ...acc,
      ...subFields,
    };
  };

  const initialValues = layout.reduce((acc: FormikAccumulator, row: LayoutRow): FormikAccumulator => {
    const columnFieldValueMapping = row.columns.reduce(getColumnFieldValueMapping, {});
    return { ...acc, ...columnFieldValueMapping };
  }, {});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnMount: true,
    validationSchema: object(validationSchema),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        setDebugLogs({ submit: values });

        if (onSubmitStart) {
          onSubmitStart();
        }

        await helpers.validateForm(values);

        const newState = (await onSubmit(values)) || FormStateType.VIEW;
        setFormState(newState);
      } finally {
        // keep
        if (onSubmitEnd) {
          onSubmitEnd();
        }
      }
    },
  });

  useEffect(() => {
    if (onChangeValues) {
      onChangeValues(formik.values, activeField, layout, formik.setFieldError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const isSubmitDisabled = Object.keys(formik.values).some(
    (key) => formik.values[key] === '' && requiredColumns.includes(key),
  );

  useEffect(() => {
    if (innerRef && formik) {
      innerRef.current = formik; // Expose Formik's methods via ref
    }
  }, [formik, innerRef]);

  const setInitialValue = (): void =>
    Object.keys(initialValues).forEach((initialValueKey) => {
      const formikValue = formik.values[initialValueKey];
      if (typeof formikValue !== 'undefined') {
        formik.setFieldValue(initialValueKey, initialValues[initialValueKey]);
      }
    });

  const handleCancel = (): void => {
    onCancel();
    setInitialValue();
    formik.resetForm();
    setTimeout(() => setFormState(FormStateType.VIEW), 10);
  };

  const isEditBlacklisted = hideEdit || blacklist?.edit.includes(currentUserRole) || false;

  const editButton = (
    <Button
      data-testid="cancel-btn"
      disabled={formik.isSubmitting}
      color={ColorType.SECONDARY}
      onClick={handleCancel}
      style={{
        border: `1px solid ${COLORS.primary}`,
      }}
      fullWidth
      className={isButtonUpperRightSide ? styles.outlineButton : ''}
    >
      <Typography
        style={{
          color: COLORS.primary,
        }}
      >
        {cancelCaption || 'Cancel'}
      </Typography>
    </Button>
  );

  const submitButton = (
    <Button
      data-testid="submit-btn"
      color={ColorType.PRIMARY}
      disabled={isSubmitDisabled || !formik.isValid || formik.isSubmitting}
      type="submit"
      fullWidth
      style={{
        border: `1px solid ${COLORS.primary}`,
      }}
      className={isButtonUpperRightSide ? styles.fillButton : ''}
    >
      {!formik.isSubmitting && (saveCaption || 'Save')}
      {formik.isSubmitting && <CircularProgress size={20} className={classes.leftIcon} />}
      {formik.isSubmitting && (submitCaption || 'Saving...')}
    </Button>
  );

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <S.HeaderForm isButtonUpperRightSide={isButtonUpperRightSide}>
          <ConditionalRender condition={!hideHeader}>
            <b>{title ?? 'Details'}</b>
          </ConditionalRender>
          <ConditionalRender condition={formState === FormStateType.VIEW && !isEditBlacklisted}>
            <Button
              data-testid="edit-btn"
              color={ColorType.SECONDARY}
              onClick={(): void => {
                setFormState(FormStateType.EDIT);
                formik.validateForm();
              }}
              style={{ marginLeft: 15, padding: '3px 15px' }}
              className={styles.outlineButton}
            >
              <div style={{ color: COLORS.primary, display: 'flex' }}>
                <EditIcon className={styles.editIcon} />
                Edit
              </div>
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={formState !== FormStateType.VIEW && isButtonUpperRightSide === true}>
            <div>
              {editButton}
              {submitButton}
            </div>
          </ConditionalRender>
        </S.HeaderForm>
        <Grid container>
          {layout.map(
            (row: LayoutRow): JSX.Element => {
              return (
                <Grid
                  item
                  id={row?.id}
                  xs={row.gridSize as GridXs}
                  key={row.row + JSON.stringify(row)}
                  style={{ padding: 10, ...row.rowStyle }}
                >
                  <ConditionalRender condition={!!row.rowHeader}>
                    <p style={{ fontWeight: 700, fontSize: '20px', marginBottom: '46px' }}>{row.rowHeader}</p>
                  </ConditionalRender>
                  {row.columns.map(
                    (column: LayoutColumn): JSX.Element => {
                      return (
                        <AdvanceFormColumn
                          currentUserRole={currentUserRole}
                          key={column.name}
                          subColumn={column}
                          formik={formik}
                          initialValues={initialValues}
                          formState={formState}
                          setFocusedField={setFocusedField}
                          acceptableImageSize={acceptableImageSize}
                          isSubmitting={formik.isSubmitting}
                          columnStyle={column?.columnStyle}
                        />
                      );
                    },
                  )}
                  <ConditionalRender condition={!!row.hasDivider}>
                    <Divider style={{ marginTop: '15px', marginBottom: '35px' }} />
                  </ConditionalRender>
                </Grid>
              );
            },
          )}
        </Grid>
        <ConditionalRender condition={formState !== FormStateType.VIEW && !isButtonUpperRightSide}>
          <Grid container style={{ marginTop: 40 }}>
            <Grid item xs={3} style={{ padding: 2 }} alignItems="center">
              {editButton}
            </Grid>
            <Grid item xs={3} style={{ padding: 2 }} alignItems="center">
              {submitButton}
            </Grid>
            <Grid item xs={12}>
              <ul>
                {Object.entries(formik.errors).map(([key, val]) => {
                  return (
                    <li key={key}>
                      {key}: {val}
                    </li>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
        </ConditionalRender>
        {debug && (
          <div style={{ width: 100, marginTop: '20px' }}>
            <div>Values: {JSON.stringify(formik.values)}</div>
            <br />
            <div>Logs: {JSON.stringify(debugLogs)}</div>
          </div>
        )}
      </form>
    </div>
  );
};
