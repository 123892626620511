import React, { useState } from 'react';
import { components } from 'react-select';
import AsyncSelect, { AsyncProps, Props as AsyncSelectProps } from 'react-select/async';

import ArrowDropDown from '@material-ui/icons/Search';

import { OptionType } from 'common/interfaces/OptionType.interface';

import { FieldMultiSelectProps } from './field-multi-select.interface';
import { multiSelectStyle } from './field-multi-select.styles';

export const ArrowDropDownIconComponent = (props: any): any => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDown />
      </components.DropdownIndicator>
    )
  );
};

interface Props extends FieldMultiSelectProps {}

export const FieldMultiSelect: React.FC<Props & AsyncSelectProps<OptionType> & AsyncProps<OptionType>> = ({
  height,
  handleChange,
  error,
  setFieldValue,
  defaultOptions,
  defaultValue: mainDefaultValue,
  allowEmptySelected,
  ...props
}) => {
  const { isMulti, name } = props;
  const [defaultValue, setDefaultValue] = useState(mainDefaultValue);

  const getOptionsNotYetSelected = (term: string): OptionType[] => {
    return defaultOptions?.filter((option: OptionType) => {
      const termInLowerCase = term.toLowerCase();
      const optionInLowerCase = option.label.toLowerCase();
      const isAlreadySelected = defaultValue
        ?.map((value: OptionType) => value.label.toLowerCase())
        .includes(optionInLowerCase);
      return optionInLowerCase.includes(termInLowerCase) && !isAlreadySelected;
    }) || [];
  }

  const defaultOptionFilter = (term: string): OptionType[] => {
    const isSimilarToLoadOption = typeof defaultOptions === 'boolean' && defaultOptions === true;

    if (isSimilarToLoadOption) {
      return [];
    }

    return getOptionsNotYetSelected(term);
  };

  const isMultiProps = {
    loadOptions: (term: string, callback: Function): void => {
      if (!defaultOptions && isMulti && !defaultValue) return;
      callback(defaultOptionFilter(term));
    },
    defaultOptions: defaultOptionFilter(''),
    cacheOptions: false,
    key: JSON.stringify(defaultValue),
  };

  const handleSetFieldValue = (value: string | OptionType[]): void => {
    if (setFieldValue && name) {
      setFieldValue(name, value);
    }
  };

  const handleOnChange = (selected: OptionType[] | OptionType): void => {
    if (!selected && !allowEmptySelected) {
      handleSetFieldValue('');
      return;
    }
    if (handleChange) {
      handleChange(selected, setFieldValue);
    }
    const fieldValue = isMulti ? (selected as OptionType[]) : (selected as OptionType).label;
    handleSetFieldValue(fieldValue);
    if (isMulti) {
      setDefaultValue(selected as OptionType[]);
    }
  };
  return (
    <AsyncSelect
      data-testid="async-select"
      border={error ? '1px solid red' : '1px solid hsl(0,0%,80%)'}
      styles={multiSelectStyle}
      maxMenuHeight={height || 300}
      onChange={(e: any) => handleOnChange(e)}
      arrowRenderer={ArrowDropDownIconComponent}
      loadOptions={(inputValue) => 
        Promise.resolve(
          defaultOptions?.filter(
            option => 
              option.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        )
      }
      defaultValue={defaultValue}
      defaultOptions={defaultOptions}
      cache={false}
      cacheOptions={false}
      {...(isMulti ? isMultiProps : {})}
      {...props}
    />
  );
};
