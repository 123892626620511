import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  checkbox: {
    color: 'black',
    '&.Mui-checked': {
      color: '#269781', 
    },
  },
  formGroup: {
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    gap: '8px', 
  },
});